<template>
  <div class="d-flex-center">
    <b-button v-if="showBtn" :variant="variant" :disabled="loading" :class="{ 'py-0': loading }" @click="preview()">
      <fluency-loader v-if="loading" dots />
      <template v-else>
        <fluency-icon type="preview" />
        {{buttonText}}
      </template>
    </b-button>
    <b-button v-if="showDeliverBtn" :variant="variant" :disabled="loading" @click="deliver()">
      <fluency-icon type="envelopeClosed" />
      Deliver Report
    </b-button>
    <manage-report-definitions-dialog v-if="report && report.userInputJson"
                                      :activate-dialog="showUserInputModal"
                                      @update:activate-dialog="showUserInputModal = $event"
                                      :report="report"
                                      @runReport="preview($event)"/>
    <async-fetcher ref="asyncFetcher"
                   file
                   hide-loader
                   @success="downloadFile($event, 'data:attachment/powerpoint', report.reportName + '.pptx')"
                   @end="loading = false"></async-fetcher>
  </div>
</template>

<script>
import FluencyLoader from 'core-ui/components/common/fluencyLoader'
import ManageReportDefinitionsDialog from '@/components/pages/manage/manageReportDefinitionsDialog'
import { downloadFile } from 'core-ui/utils/downloadFile'
import AsyncFetcher from 'core-ui/components/common/asyncFetcher'
import { useAccountsMinimal } from '@/composables/useAccountsMinimal'

export default {
  name: 'HtmlReportPreview',
  components: { AsyncFetcher, FluencyLoader, ManageReportDefinitionsDialog },
  props: {
    report: {
      type: Object
    },
    variant: {
      type: String,
      default: 'ghost-info'
    },
    buttonText: {
      type: String,
      default: 'Preview'
    },
    showBtn: {
      type: Boolean,
      default: true
    },
    showDeliverBtn: {
      type: Boolean,
      default: false
    },
    accountsProp: {
      type: Array
    }
  },
  setup () {
    const { accountsMinimal } = useAccountsMinimal()
    return {
      accountsMinimal
    }
  },
  data () {
    return {
      reportData: undefined,
      loading: false,
      showUserInputModal: false
    }
  },
  computed: {
    reportId () {
      return this.report.reportId
    },
    reportScope () {
      return this.report.scope
    },
    reportFormat () {
      return this.report.format
    }
  },
  methods: {
    async promptForAccount () {
      if (this.accountsMinimal().length === 0) {
        return
      }
      const accountSelection = await this.$prompt({
        title: 'Select an Account',
        'body-class': 'overflow-visible',
        centered: false,
        forms: [
          {
            name: 'accountPlanId',
            required: true,
            label: 'Account',
            inputProps: {
              'require-option': true,
              'disable-auto-focus': false,
              'retain-value-on-focus': false,
              'type-ahead-options': this.accountsMinimal()
            }
          }
        ]
      })
      if (!accountSelection?.accountPlanId) {
        return
      }
      return accountSelection.accountPlanId
    },
    async openWindowAndInjectData (accountPlanId, input) {
      const timeOut = 3000

      const currentUrlParams = new URLSearchParams(window.location.search)
      const queryParams = []
      if (currentUrlParams.has('fsEnv')) {
        queryParams.push(`fsEnv=${currentUrlParams.get('fsEnv')}`)
      }
      const query = queryParams.length > 0 ? `?${queryParams.join('&')}` : ''
      const win = window.open(`/reporting/preview${query}`)
      window.setTimeout(() => {
        win.document.dispatchEvent(new Event('dataLoading'))
      }, timeOut)
      const reportData = await this.$res.reporting.getReportContent(this.reportId, accountPlanId, input)
      if (reportData) {
        const script = document.createElement('script')
        script.innerHTML = `window.getChartData = () => new Promise((resolve) => resolve({data:${JSON.stringify(reportData)}}))`
        const head = win.document.head || win.document.getElementsByTagName('head')[0]
        if (!head) return
        head.appendChild(script)
        window.setTimeout(() => {
          win.document.dispatchEvent(new Event('dataReady'))
        }, timeOut)
      } else {
        window.setTimeout(() => {
          win.document.dispatchEvent(new Event('dataError'))
        }, timeOut)
      }
    },
    async processPresentation (accountPlanId) {
      this.$refs.asyncFetcher.asyncFetch(this.$res.reporting.asyncProcessPresentation, [this.report, accountPlanId])
    },
    downloadFile,
    async preview (input) {
      if (this.report.userInputJson && !input) {
        this.showUserInputModal = true
        return
      }
      let accountPlanId = this.accountsProp?.[0]
      if (this.reportScope === 'ACCOUNT') {
        if (!accountPlanId) {
          accountPlanId = await this.promptForAccount()
          if (!accountPlanId) return
        }
        if (this.accountsProp?.length > 1) {
          const cfm = await this.$confirm({
            title: 'Hang On',
            text: 'You can only preview this report for one account at a time. We\'ll run it for your first selected account.',
            'ok-title': 'Let\'s Do It'
          })
          if (!cfm) return
        }
      }
      this.loading = true
      if (this.reportFormat === 'PPT') {
        await this.processPresentation(accountPlanId)
        return
      } else if (this.reportFormat === 'PDF') {
        await this.openWindowAndInjectData(accountPlanId, input)
      }
      this.loading = false
    },
    async deliver () {
      let accountPlanId
      if (this.reportScope === 'ACCOUNT') {
        accountPlanId = await this.promptForAccount()
        if (!accountPlanId) return
      }
      const payload = { reportId: this.report.reportId }
      if (accountPlanId) {
        payload.accountPlanId = accountPlanId
      }
      const response = await this.$res.reporting.generateReportExport(payload)
      if (response) {
        this.$toast('Report Generated', 'success')
      }
    }
  }
}
</script>
